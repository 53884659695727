import React, { useState } from "react";
import NavbarComponent from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import "../components/Styles/Contact.css";
import { Helmet } from "react-helmet";
import Drcr from "../components/Drcr/Drcr";
import axios from "axios";
import CookieComponent from "../components/CookieComponent/CookieComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const toastError = () => {
    toast.error("Please fill in all of the fields!", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [showSubmitMessage, setShowSubmitMessage] = useState(false);

  function submittedData(e) {
    e.preventDefault();
    if (!data.email || !data.message || !data.name || !data.subject) {
      toastError();
    } else {
      submit();
    }
  }

  const url = "https://prod.swifty-api.com/main/v1/feedback_website";
  const [data, setData] = useState({
    email: "",
    name: "",
    message: "",
    subject: "",
  });

  function submit() {
    let body = {
      email: data.email,
      name: data.name,
      message: data.message,
      subject: data.subject,
    };
    axios.put(url, body).then((res) => {
      console.log(res.data);
      setShowSubmitMessage(true);
    });
  }
  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }
  return (
    <div className="container-xxl contact">
      <Drcr />
      <div className="container">
        <Helmet>
        <title>Contact Us | Swifty Global</title>  
        <meta
            name="description"
            content="Reach out to Swifty Global for any questions, service inquiries, or customer support. We look forward to hearing from you!"
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content="Contact Us | Swifty Global"
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content="Reach out to Swifty Global for any questions, service inquiries, or customer support. We look forward to hearing from you!"
            data-react-helmet="true"
          />
            <meta
            property="og:type"
            content="website"
            data-react-helmet="true"
          />
          <meta
            property="og:url"
            content="https://www.swifty.global/contact"
            data-react-helmet="true"
          />
        </Helmet>
        <NavbarComponent />
        <div className="row contact">
          <div className="col-md-6" id="contact-banner">
            <h3 className="banner-title-contact">Contact Us</h3>
            <p className="banner-text-contact">
              Need help, support or just want to drop us a message? <br />{" "}
              <br />
              Fill out the form or write to us on one of the channels listed
              below.
            </p>
          </div>
          <div className="col-md-6"></div>
          <div
            className="col-md-8 col-sm-8 col-lg-8 contact-form"
            id="contact-form"
          >
            <span>
              <div className="btn-group-vertical">
                {showSubmitMessage ? (
                  <>
                    <img
                      src="../images/check-mark.svg"
                      alt="Submitted Icon"
                      className="submit-icon"
                    />
                    <p className="submit-message">
                      Thank you for your message. <br /> Someone from the Swifty
                      Team will aim to come back to you within 72 hours.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="contact-title">Drop us a line...</p>
                    <form onSubmit={(e) => submittedData(e)}>
                      <span>
                        <input
                          onChange={(e) => handle(e)}
                          id="name"
                          value={data.name}
                          type="text"
                          className="contact-buttons"
                          placeholder="Full Name"
                        />
                      </span>
                      <span>
                        <input
                          onChange={(e) => handle(e)}
                          id="email"
                          value={data.email}
                          type="email"
                          className="contact-buttons"
                          placeholder="Email address"
                        />
                      </span>
                      <span className="topic-button">
                        <select
                          onChange={(e) => handle(e)}
                          id="subject"
                          value={data.subject}
                          name="topics"
                          className="contact-buttons select"
                          placeholder="Select a topic"
                        >
                          <option value>Select a topic</option>
                          <option value="Topic 1">General Feedback</option>
                          <option value="Topic 2">Bug or issue</option>
                          <option value="Topic 3">Marketing or media</option>
                          <option value="Topic 4">Complaint</option>
                        </select>
                      </span>
                      <span>
                        <textarea
                          cols="40"
                          rows="4"
                          onChange={(e) => handle(e)}
                          id="message"
                          value={data.message}
                          type="text"
                          className="contact-buttons"
                          placeholder="Leave a message"
                        />
                      </span>
                      <div className="submit-button-span">
                        <button
                          type="submit"
                          value="Submit"
                          className="submit-button"
                          onClick={submittedData}
                        >
                          Submit
                        </button>
                        <ToastContainer
                          position="top-right"
                          autoClose={4000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      </div>
                    </form>
                  </>
                )}
              </div>
            </span>
          </div>
          <div
            className="col-md-4 col-sm-4 col-lg-4 social-form"
            id="reach-us-form"
          >
            <span className="social-media-title">Reach us</span>
            <span className="social-divider" />
            <div className="social-media-form">
              <span className="social-media-accounts">
                <img src="../images/@.svg" alt="Swifty Global Email" />
                <a href="mailto:hello@swifty.global">
                  <p className="accounts">hello@swifty.global</p>
                </a>
              </span>
              <span className="social-media-accounts">
                <img
                  src="../images/twitter-white.svg"
                  alt="Swifty Global Twitter Account"
                />
                <a href="https://twitter.com/SwiftyGlobal" target="_blank">
                  <p className="accounts">@swifty.global</p>
                </a>
              </span>
              <span className="social-media-accounts">
                <img
                  src="../images/insta-white.svg"
                  alt="Swifty Global Instagram Account"
                />
                <a
                  href="https://www.instagram.com/swiftysports_sb/"
                  target="_blank"
                >
                  <p className="accounts"> @swiftyglobal</p>
                </a>
              </span>
              {/* <span className="social-media-accounts">
                <img
                  src="../images/telegram-white.svg"
                  alt="Swifty Global Telegram"
                />
                <a href="https://t.me/swiftyglobal" target="_blank">
                  <p className="accounts">/swiftyglobal</p>
                </a>
              </span> */}
              <span className="social-media-accounts">
                <img
                  src="../images/linkedin-white.svg"
                  alt="Swifty Global LinkedIn Account"
                />
                <a
                  href="https://www.linkedin.com/company/swifty-global"
                  target="_blank"
                >
                  <p className="accounts">/swifty-global</p>
                </a>
              </span>
            </div>
          </div>
          <div className="col-md-4 social-form" id="locations">
            <span className="social-media-title">London</span>
            <span className="social-divider" />
            <div className="social-media-form">
              <span className="social-media-accounts">
                <img src="../images/@.svg" alt="Swifty Global Email" />
                <a href="mailto:hello@swifty.global">
                  <p className="accounts">hello@swifty.global</p>
                </a>
              </span>
              <span className="social-media-accounts">
                <img
                  src="../images/phone-icon.svg"
                  alt="Swifty Global London Contact Number"
                />
                <a href="tel:+44 (0) 204 542 4517">
                  <p className="accounts">+44 (0) 204 542 4517</p>
                </a>
              </span>
              <span className="social-media-accounts">
                <img
                  src="../images/location-icon.svg"
                  alt="Swifty Global London Office Location"
                />
                <p className="accounts">
                  71-75, Shelton Street, Covent <br /> Garden, London, WC2H 9JQ,
                  <br />
                  United Kingdom
                </p>
              </span>
            </div>
          </div>
          <div className="col-md-4 social-form" id="locations">
            <span className="social-media-title">New York</span>
            <span className="social-divider" />
            <div className="social-media-form">
              <span className="social-media-accounts">
                <img src="../images/@.svg" alt="Swifty Global Email" />
                <a href="mailto:hello@swifty.global">
                  <p className="accounts">hello@swifty.global</p>
                </a>
              </span>
              <span className="social-media-accounts">
                <img
                  src="../images/phone-icon.svg"
                  alt="Swifty Global New York Contact Number"
                />
                <a href="tel:+1 (917) 970-1433">
                  <p className="accounts">+1 (917) 970-1433</p>
                </a>
              </span>
              <span className="social-media-accounts">
                <img
                  src="../images/location-icon.svg"
                  alt="Swifty Global New York Office Location"
                />
                <p className="accounts">
                  26 Broadway, Suite 934, New <br /> York, NY 10004, USA
                </p>
              </span>
            </div>
          </div>
          <div className="col-md-4 social-form" id="locations">
            <span className="social-media-title">Dubai</span>
            <span className="social-divider" />
            <div className="social-media-form">
              <span className="social-media-accounts">
                <img src="../images/@.svg" alt="Swifty Global Email" />
                <a href="mailto:hello@swifty.global">
                  <p className="accounts">hello@swifty.global</p>
                </a>
              </span>
              <span className="social-media-accounts">
                <img
                  src="../images/phone-icon.svg"
                  alt="Swifty Global Dubai Contact Number"
                />
                <a href="tel:+971 (0) 4 512 4070">
                  <p className="accounts">+971 (0) 4 512 4070</p>
                </a>
              </span>
              <span className="social-media-accounts">
                <img
                  src="../images/location-icon.svg"
                  alt="Swifty Global Dubai Office Location"
                />
                <p className="accounts">
                  Office 1105, Marina Plaza, <br /> Marina, Dubai, UAE
                </p>
              </span>
            </div>
          </div>
          <Footer />
          <CookieComponent />
        </div>
      </div>
    </div>
  );
};
export default Contact;
